export default class Product {
  constructor(product) {
    Object.assign(this, product);
    this.defaultPV = product.defaultProductVariant;
    // this.defaultPV = product.productVariantList.find(pv => pv.id === product.defaultProductVariant) || product.productVariantList[0];
  }

  getMedialList () {
    return [...this.productVariantList.map(pv => pv.medialList)];
  }

  getSingleVariantFeature (variantFeatureType) {
    return this.variantFeatureList.find(vf => vf.type === variantFeatureType) || {};
  }
}
