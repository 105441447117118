export const ROOT = '/';
export const HOME = '/home';
export const AUTH = {
    BASE: '/user',
    LOGIN: '/user/login',
    REGISTER: '/user/register',
    TOKEN: '/user/branch/activation',
    FORGOT_PASSWORD: '/user/forgot-password',
    RESET_PASSWORD: '/user/reset-password',
};

export const PRODUCTS = {
    BASE: '/products',
    SELF: '/products',
    LIST: '/products/list',
    CREATE: '/products/create',
    UPDATE: '/products/:id/update',
    DETAILS: '/products/:id/details',
};

export const CATEGORIES = {
    SELF: '/categories',
    LIST: '/categories/list',
    CREATE: '/categories/create',
    UPDATE: '/categories/update/:id',
};

export const USERS = {
    SELF: '/users',
    LIST: '/users/list',
    PROFILE: '/users/:id/profile',
};

export const ORDERS = {
    SELF: '/orders',
    LIST: '/orders/list',
    SHOW: '/orders/:id/details',
};

export const BRANDS = {
    SELF: '/brands',
    LIST: '/brands/list',
    CREATE: '/brands/create',
    UPDATE: '/brands/update/:id',
};

export const PROMOTIONS = {
    BASE: '/promotions',
    SELF: '/promotions',
    VOUCHERS: {
        SELF: '/promotions/vouchers',
        LIST: '/promotions/vouchers/list',
        CREATE: '/promotions/vouchers/create',
    },
    SALES: {
        SELF: '/promotions/sales',
        LIST: '/promotions/sales/list',
        CREATE: '/promotions/sales/create',
        UPDATE: '/promotions/sales/:id/update',
    },
};

export const TAXES = {
    LIST: '/taxes',
    CREATE: '/taxes/create',
};

export const SETTINGS = {
    SELF: '/settings',
    CURRENCY: {
      LIST: '/settings/currencies',
      CREATE: '/settings/currencies/create',
      UPDATE: '/settings/currencies/update',
    },
    HOME_PAGE_LAYOUT: '/settings/home-page-layout',
};

export const REVIEWS = {
    LIST: '/reviews',
};

export const INBOX = {
    LIST: '/inbox',
};

export const joinUrlWithParams = (to, params) => {
    let url = to;
    params.forEach(param => {
        url = url.replace(`:${param.param}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

export const joinUrlWithParamsId = (to, id) => {
    return joinUrlWithParams(to, [{ param: 'id', value: id }]);
};
