/**
 * Helpers Functions
 */
import _ from 'lodash';
import dayjs from 'dayjs';
import api from "./axiosHelper";
import Types from '../enums/Types';
import Status from '../enums/Status';
import * as Constants from './../constants/defaultValues';
// import NavLinks from "Components/Sidebar/NavLinks";
// import {NotificationManager} from 'react-notifications';

const TABLE_OF_256_HEXADECIMAL = (function () {
  const arr = [];
  for (let i = 0; i < 256; i++) { arr[i] = (i < 16 ? '0': '') + (i).toString(16); }
  return arr;
})();

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
  }
  throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (!str) {
    return "";
  }
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

function getAmount(currencies, amount, from, to = null, currency = null) {
  if (from)
    if (to) {
      let from_currency = currencies.filter(c => c.code == from)[0];
      if(!from_currency)
        from_currency = {code: 'EUR', value: 1};
      let to_currency = currencies.filter(c => c.code == to)[0];
      let main_amount = amount * from_currency.value;
      return main_amount / to_currency.value;
    } else {
      let from_currency = currencies.filter(c => c.code == from)[0];
      if(!from_currency)
        from_currency = {code: 'EUR', value: 1};
      let to_currency = null;
      if (currency)
        to_currency = currency;
      else
        to_currency = currencies.filter(c => c.main == true)[0];
      let main_amount = amount * from_currency.value;
      return main_amount / to_currency.value;
    }
  else {
    let from_currency = currencies.filter(c => c.main == true)[0];
    if (!from_currency)
      from_currency = { code: 'EUR', value: 1 };
    let to_currency = null;
    if (currency)
      to_currency = currency;
    else
      to_currency = currencies.filter(c => c.main == true)[0];
    let main_amount = amount * from_currency.value;
    return main_amount / to_currency.value;
  }
}

function getAmounts(currencies, amounts, to = null, currency = null) {
  if (to) {
    let amount = 0;
    let from_currency = null;
    let to_currency = currencies.filter(c => c.code == to)[0];
    amounts.forEach(a => {
      from_currency = currencies.filter(c => c.code == a.currency)[0];
      let main_amount = 0;
      if (!from_currency)
        from_currency = { code: 'EUR', value: 1 };
      if (a.quantity)
        main_amount = (a.amount * a.quantity) * from_currency.value;
      else
        main_amount = a.amount * from_currency.value;

      amount = amount + (main_amount / to_currency.value);
    });
    return amount;
  } else {
    let amount = 0;
    let from_currency = null;
    let to_currency = null;

    if (currency)
      to_currency = currency;
    else
      to_currency = currencies.filter(c => c.main == true)[0];

    amounts.forEach(a => {
      from_currency = currencies.filter(c => c.code == a.currency)[0];
      let main_amount = 0;
      if (!from_currency)
        from_currency = { code: 'EUR', value: 1 };
      if (a.quantity != null)
        main_amount = (a.amount * a.quantity) * from_currency.value;
      else
        main_amount = a.amount * from_currency.value;

      amount = amount + (main_amount / to_currency.value);
    });
    return amount;
  }
}

export function computeAmountFromCurrency(currencies, amount = null, amounts = null, currency = null, from = null, to = null) {
  if(amount != null) {
    return getAmount(currencies, amount, from, to, currency);
  } else {
    return getAmounts(currencies, amounts, to, currency);
  }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
  let time = timestamp * 1000;
  let formatDate = format ? format : 'MM-DD-YYYY';
  return dayjs(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : 'YYYY-MM-DD';
  return dayjs(date, formatDate).unix();
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
  let location = url.pathname;
  let path = location.split('/');
  return path[1];
}

export const getLocaleFromBrowser = () => {
  const browserLanguage = window.navigator.userLanguage || window.navigator.language;
  return browserLanguage.split('-')[0];
};

export function getDefaultLanguage() {
  const browserLocale = getLocaleFromBrowser();
  let defaultLanguage = Constants.localeOptions.find(l => l.id.includes(browserLocale));

  if (!defaultLanguage) {
      defaultLanguage = Constants.localeOptions[0];
  }

  return defaultLanguage;
  // return Constants.localeOptions
}



/**
 * Deep mapping an object
 * @param obj
 * @param callback function to apply to the key
 */
export function deepMapObject(obj, callback, appliedDayJs = true) {
  let rtn = obj;
  if (typeof (obj) === 'object') {
    if (obj instanceof Array) {
      rtn = obj.map(item => deepMapObject(item, callback));
    } else {
      rtn = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          // apply the change on the string
          const newKey = callback(key);

          // Falsy or primitive value
          if (!obj[key] || typeof obj[key] !== 'object') {
            if (appliedDayJs && doesKeyValueMatchDate(newKey, obj[key])) {
              rtn[newKey] = dayjs(obj[key]);
            } else rtn[newKey] = obj[key];
          }
          // nested object
          else rtn[newKey] = deepMapObject(obj[key], callback);
        }
      }
    }
  }
  return rtn;
}

/**
 * Convert an object to camelCase
 * @param obj
 */
export function toCamelCase(obj) {
  // function to execute on each key
  const callback = key => key.replace(/(_\w)/g, k => k[1].toUpperCase());

  // call a generic method
  return deepMapObject(obj, callback);
}

/**
 * Convert a string to snake case
 * @param str
 * @returns {*}
 */
export function toStringSnakeCase(str) {
  // call a generic method
  return str.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
}

/**
 * Convert an object to snake case
 * @param obj
 */
export function toSnakeCase(obj) {
  // function to execute on each key
  const callback = key => key.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');

  // call a generic method
  return deepMapObject(obj, callback);
}

export const doesKeyValueMatchDate = (key, value) => {
    return /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value);
};

export const objectToFormData = (obj) => {
  let formData = new FormData();
  if (obj instanceof FormData) {
    formData = obj;
  } else {
    Object.keys(obj).forEach(key => formData.append(key, obj[key]));
  }
  return formData;
};

/**
 * Check if the user's value into store is valid
 *
 * @param authUserData
 */
export const isUserIntoStoreValid = (authUserData) => {
  return authUserData !== null && authUserData !== undefined;
};

/**
 * Get query of url
 * @param useLocation
 * @returns {URLSearchParams}
 */
export const useQuery = (useLocation) => new URLSearchParams(useLocation().search);

export const getFullAuthorisationRequestConfig = () => {
  const headers = {
    'Content-type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: 'Basic ' + btoa(Constants.backendUrl.oauth.clientId + ":" + Constants.backendUrl.oauth.clientSecret)
  };
  return { headers, shouldSkipToken: true, withCredentials: true };
};
/*"KEY_1": {
    "ERROR_1": ERROR_1_MESSAGE,
    "ERROR_2": ERROR_1_MESSAGE,
},
"KEY_2": {
    "ERROR_1": ERROR_1_MESSAGE
}*/
export const requestErrorProcessing = (data) => {
  /*const result = {};
  Object.entries(data.errors).map(error => {
      // error[0] = KEY_1
      result[error[0]] = typeof error[1] === 'object' ? Object.values(error[1]) : error[1];
  });*/
  const result = [];
  Object.entries(data.errors).map(error => {
    result.push(typeof error[1] === 'object' ? Object.values(error[1]) : error[1]);
  });
  return result;
};

const deepStringify = val => {
    if (typeof val === "object") {

    }
};

export const globalSearch = (data, searched) => {
  if (!searched) {
    return data;
  }

  const _searched = typeof searched === 'string' ? searched.toLowerCase() : searched;
  return _.filter(data, o => {
    const values = Object.values(o);
    return Object.values(o)
      .filter(f => typeof f === 'string' || typeof f === 'number')
      .join(' ')
      .toLowerCase()
      .includes(_searched)
  });
};

/**
 * Return array of permissions of a path
 * @param path
 * @returns {Array}
 */
/*export const getPermissionOfPath = (path) => {
  const currentMenus = NavLinks.menus;

  let permissions = [];
  for (let item of currentMenus) {
    if (item.path === path) {
      permissions = p.permissions;
      break;
    } else if (item.child_routes) {
      for (let subItem of item.child_routes) {
        if (subItem.path === path) {
          permissions = subItem.permissions;
          break;
        }
      }
    }
  }

  return permissions;
};*/

export const getAvailableItems = (totalItems, occupiedItems) => {
  if (totalItems && occupiedItems) {
    const occupiedItemsId = occupiedItems.map(p => p.id);
    return totalItems.filter(p => !occupiedItemsId.includes(p.id));
  }
  else return [];
};

/**
 * Extension of can action to handle array
 * @param permissions {Array}
 * @param some {boolean}
 * @returns {boolean|*}
 */
export const canArray = (permissions, some = true) => {
  if (permissions && Array.isArray(permissions)) {
    // If the array is empty then the user have permissions since there is no restrictions to that
    if (permissions.length === 0) return true;

    return permissions.reduce((a,b) => some
      ? a || b
      : a && b
    );
  }

  return false;
};


/**
 * Generate an unique id
 *
 * From StackOverFlow https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 *
 * @returns {string}
 */
const getUniqueId = () => {
  const d0 = Math.random()*0xffffffff|0;
  const d1 = Math.random()*0xffffffff|0;
  const d2 = Math.random()*0xffffffff|0;
  const d3 = Math.random()*0xffffffff|0;
  return TABLE_OF_256_HEXADECIMAL[d0&0xff]+TABLE_OF_256_HEXADECIMAL[d0>>8&0xff]+TABLE_OF_256_HEXADECIMAL[d0>>16&0xff]+TABLE_OF_256_HEXADECIMAL[d0>>24&0xff]+'-'+
    TABLE_OF_256_HEXADECIMAL[d1&0xff]+TABLE_OF_256_HEXADECIMAL[d1>>8&0xff]+'-'+TABLE_OF_256_HEXADECIMAL[d1>>16&0x0f|0x40]+TABLE_OF_256_HEXADECIMAL[d1>>24&0xff]+'-'+
    TABLE_OF_256_HEXADECIMAL[d2&0x3f|0x80]+TABLE_OF_256_HEXADECIMAL[d2>>8&0xff]+'-'+TABLE_OF_256_HEXADECIMAL[d2>>16&0xff]+TABLE_OF_256_HEXADECIMAL[d2>>24&0xff]+
    TABLE_OF_256_HEXADECIMAL[d3&0xff]+TABLE_OF_256_HEXADECIMAL[d3>>8&0xff]+TABLE_OF_256_HEXADECIMAL[d3>>16&0xff]+TABLE_OF_256_HEXADECIMAL[d3>>24&0xff];
};

/**
 * Get or create session id
 * @returns {string}
 */
export const getSessonId = () => {
  const sessionId = localStorage.getItem('ssid');
  if (sessionId) {
    return sessionId;
  } else {
    const newSessionId = getUniqueId();
    localStorage.setItem('ssid', newSessionId);
    return newSessionId;
  }
};

export function getFilePath(file) {
  if(file)
    if(file.startsWith('http') && file.includes(':')) {
      return file;
    } else {
      return `${Constants.backendUrl.baseUrl}/${file}`
    }
}

export const copyToClipboard = (text) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
      }

      // NotificationManager.success("Lien copié");
      resolve();
    } catch (e) {
      // NotificationManager.error("Impossible de copier le lien");
      reject();
    }
  })
};


/**
 * Perform normal request
 * @param verb
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
export const makeRequest = (verb, url, data = null, config = {}) => {
  return new Promise((resolve, reject) => {
    let _url = url;
    if ((verb === 'get' || verb === 'delete') && data) {
      Object.entries(data).map(item => {
        const encoded = encodeURIComponent(item[1]);
        const character = _url.includes('?') ? '&' : '?';
        _url = `${_url}${character}${toStringSnakeCase(item[0])}=${encoded}`
      });
    }
    const params = (verb === 'get' || verb === 'delete') ? [_url, config] : [_url, data, config];
    api[verb](...params)
      .then(result => resolve(result.data))
      .catch(error => reject(error));
  });
};

/**
 * Perform action request
 * @param verb
 * @param url
 * @param typeBase
 * @param dispatch
 * @param data
 * @param config
 * @returns {Promise<any> | * | Promise<T | never> | undefined}
 */
export const makeActionRequest = (verb, url, typeBase, dispatch, data = null, config = {} ) => {
  dispatch({ type: typeBase });
  return makeRequest(verb, url, data, config)
    .then((response) => {
      dispatch({ type: `${typeBase}_SUCCESS`, payload: response });
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch({ type: `${typeBase}_FAILURE` });
      return Promise.reject(error);
    });
};

/**
 * Get visible dimensions of a node
 * @param node
 * @returns {{width: number, height: number}}
 */
export function visibleDimensions(node) {
  let o = {height: node.offsetHeight, width: node.offsetWidth}, // size
    d = {y: (node.offsetTop || 0), x: (node.offsetLeft || 0), node: node.offsetParent}, // position
    css, y, x;
  while( null !== (node = node.parentNode) ){  // loop up through DOM
    css = window.getComputedStyle(node);
    if( css && css.overflow === 'hidden' ){  // if has style && overflow
      y = node.offsetHeight - d.y;         // calculate visible y
      x = node.offsetWidth - d.x;          // and x
      if( node !== d.node ){
        y = y + (node.offsetTop || 0);   // using || 0 in case it doesn't have an offsetParent
        x = x + (node.offsetLeft || 0);
      }
      if( y < o.height ) {
        if( y < 0 ) o.height = 0;
        else o.height = y;
      }
      if( x < o.width ) {
        if( x < 0 ) o.width = 0;
        else o.width = x;
      }
      return o;                            // return (modify if you want to loop up again)
    }
    if( node === d.node ){                   // update offsets
      d.y = d.y + (node.offsetTop || 0);
      d.x = d.x + (node.offsetLeft || 0);
      d.node = node.offsetParent;
    }
  }
  return o;                                    // return if no hidden
}

export const formatSize = (size) => {
  if (size > 1024 * 1024 * 1024)
    return {
      originalSize: size,
      formattedSize: `${_.round(size / (1024 * 1024 * 1024), 2)} GB`,
    };
  else if (size > 1024 * 1024)
    return {
      originalSize: size,
      formattedSize: `${_.round(size / (1024 * 1024), 2)} MB`,
    };
  else if (size > 1024)
    return {
      originalSize: size,
      formattedSize: `${_.round(size / 1024, 2)} KB`,
    };
  else return {
      originalSize: size,
      formattedSize: `${_.round(size, 2)} Bytes`,
    }
};

/**
 * Get currency
 * @returns {string}
 */
export const getCurrency = () => {
  return `CA$`;
};

/**
 * Format a price in Canadian dollar
 * @param price
 * @returns {string}
 */
export const formatPrice = (price) => {
  return `${getCurrency()}${Number(price).toFixed(2)}`;
};

/**
 * Format a price in given currency
 * @param price
 * @param currency
 * @param skipRate
 * @returns {string}
 */
export const formatPriceWithCurrency = (price, currency, skipRate = false) => {
  let _price = !skipRate ? Number(price) * currency.rate : Number(price);
  _price = price.toFixed(2);

  return currency.position === Types.BEGINNING
    ? `${currency.symbol}${_price}`
    : `${_price}${currency.symbol}`;
};

/**
 * Capitalize the first letter of a word
 * @param word
 * @returns {string}
 */
export const wordCapitalize = (word) =>  {
  if(!word)
    return word
  return word[0].toUpperCase() + word.substring(1, word.length).toLowerCase();
}

/**
 * Return currency and percentage directly usable by react-select
 * @type {{defaultValue: {label: string, value: string}, options: {label: string, value: string}[]}}
 */
export const perCurOptions = {
  options: [Types.CURRENCY, Types.PERCENT].map(t => ({label: wordCapitalize(t.toLowerCase()), value: t})),
  defaultValue: {label: wordCapitalize(Types.CURRENCY.toLowerCase()), value: Types.CURRENCY},
  getOneOption: (type) => ({label: wordCapitalize(type.toLowerCase()), value: type})
};

/**
 * Display value
 * @param value
 * @param couponType
 * @returns {string}
 */
export const displayValue = (value, couponType) => {
  return couponType === Types.CURRENCY ? formatPrice(value) : `${value}%`;
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Get language name from locale
 * @param locale
 * @returns {string}
 */
export const getLanguageName = (locale) => {
    switch (locale) {
      case 'fr':
        return 'French';
      case 'en':
        return 'English';
      default:
        return 'English';
    }
};

/**
 * Get a good status name
 * @param status
 * @returns {string}
 */
export const getStatusName = (status) => {
    return wordCapitalize(status.toLowerCase());
};

/**
 * Get a status color for a given color
 * @param state
 * @returns {string}
 */
export const getStatusColorClassName = (state) => {
    switch (state) {
      case Status.PAID:
        return "primary";
      case Status.TREATED:
        return "secondary";
      default:
        return "success";
    }
};

/**
 * Download a file by its url
 * @param url
 */
export const downloadContent = (url) => {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  a.target = "_blank";

  a.href = url;

  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

/**
 * Check if a number is numeric.
 * Optionally allow a filter to allow only positive or negative number
 * @param num
 * @param sign null || '+' || '-'
 * @returns {boolean}
 */
export const isNumeric = (num, sign = null) => {
  const _isNumeric = (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num);
  return sign === null
    ? _isNumeric
    : sign === '+'
      ? _isNumeric && num >= 0
      : _isNumeric && num <= 0;
};

export function captureVideoFrame(video, format, quality) {
  if (typeof video === 'string') {
    video = document.getElementById(video);
  }

  format = format || 'jpeg';
  quality = quality || 0.92;

  if (!video || (format !== 'png' && format !== 'jpeg')) {
    return false;
  }

  var canvas = document.createElement("CANVAS");

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext('2d').drawImage(video, 0, 0);

  var dataUri = canvas.toDataURL('image/' + format, quality);
  var data = dataUri.split(',')[1];
  var mimeType = dataUri.split(';')[0].slice(5)

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([ arr ], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
};

export function captureFrame (video, format) {
  if (typeof video === 'string') {
    video = document.querySelector(video)
  }

  if (video == null || video.nodeName !== 'VIDEO') {
    throw new TypeError('First argument must be a <video> element or selector')
  }

  if (format == null) {
    format = 'png'
  }

  if (format !== 'png' && format !== 'jpeg' && format !== 'webp') {
    throw new TypeError('Second argument must be one of "png", "jpeg", or "webp"')
  }

  const canvas = document.createElement('canvas')
  const width = canvas.width = video.videoWidth
  const height = canvas.height = video.videoHeight

  canvas.getContext('2d').drawImage(video, 0, 0)

  const dataUri = canvas.toDataURL('image/' + format)
  const data = dataUri.split(',')[1]

  var mimeType = dataUri.split(';')[0].slice(5)

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([ arr ], { type: mimeType });

  return {
    blob,
    image: Buffer.from(data, 'base64'),
    width,
    height
  }
}
/*
export function captureVideoFrame(videoSrc) {
  const video = document.createElement('video')
  video.addEventListener('canplay', onCanPlay)

  video.volume = 0
  video.autoplay = true
  video.muted = true // most browsers block autoplay unless muted
  video.setAttribute('crossOrigin', 'anonymous') // optional, when cross-domain
  video.src = videoSrc;

  function onCanPlay () {
    video.removeEventListener('canplay', onCanPlay)
    video.addEventListener('seeked', onSeeked)

    video.currentTime = 2 // seek 2 seconds into the video
  }

  function onSeeked () {
    video.removeEventListener('seeked', onSeeked)

    const frame = captureFrame(video)

    // unload video element, to prevent memory leaks
    video.pause()
    video.src = ''
    video.load()

    // show the captured image in the DOM
    const image = document.createElement('img')
    image.width = frame.width
    image.height = frame.height
    image.src = window.URL.createObjectURL(new window.Blob([frame.image]))
    document.body.appendChild(image)
  }
}*/

export function getParticularPaymentFees(totalAmount, paymentMethod, round = true) {
  let result = 0;
  if (paymentMethod) {
    result = (paymentMethod.fees / (100 - paymentMethod.fees)) * totalAmount;
  }
  return round ? result.toFixed(2) : result;
}

export function getNetFees(totalAmount, paymentMethod, round = true) {
  let result = 0;
  if (paymentMethod) {
    result = (paymentMethod.fees / (100 - paymentMethod.fees)) * totalAmount;
  }
  return round ? (totalAmount - result).toFixed(2) : totalAmount - result;
}

export function getVoucherValue(amount, voucher, round = true) {
  let result = 0;
  if (voucher) {
      if (voucher.type === "PERCENT") {
        result = (amount * voucher.value) / 100;
      } else {
        result = voucher.value;
      }
  }
  return round ? result.toFixed(2) : result;
}