import axios from 'axios';
import * as Constants from './../constants/defaultValues';
import { objectToFormData, toCamelCase, toSnakeCase, toStringSnakeCase } from './funcHelper';
import { getAuthToken, removeAuthToken, saveAuthToken } from "./userHelper";

function getBinaryFromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", err => reject(err));

    reader.readAsBinaryString(file);
  });
}


/**
 * Perform async task while looping
 *
 * Reference:
 *  - https://stackoverflow.com/questions/40328932/javascript-es6-promise-for-loop
 *  - https://github.com/Download/for-async/blob/master/src/for-async.js
 * @param arr
 * @param work
 * @returns {*|Promise<any>}
 */
const forAsync = (arr, work) => {
  function loop(arr, i) {
    return new Promise((resolve, reject) => {
      if (i >= arr.length) {resolve()}
      else try {
        Promise.resolve(work(arr[i], i))
        /*.then(() => resolve(loop(arr, i+1)))
        .catch(reject)*/
          .finally(() => resolve(loop(arr, i+1)));
      } catch(error) {reject(error)}
    })
  }
  return loop(arr, 0);
};


const g = async (files) => {
  const result = [];
  await forAsync(files, (item, index) => {
    return new Promise(async (resolve, reject) => {
      const a = await getBinaryFromFile(item);
      result.push(a);
      resolve(a);
    });
  });
  return result;
};

const customAxios =
  axios.create({
    baseURL: Constants.backendUrl.baseUrl,
    timeout: Constants.backendUrl.timeout
  });

customAxios.interceptors.request.use(
  async (config) => {
    try {
      // Get auth token
      const { accessToken } = getAuthToken();

      if (accessToken && !config.shouldSkipToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      if (config.multipart) {
        config.headers['content-type'] = 'multipart/form-data';
      }

      // Check if post or put to perform some operation
      if ((config.method === 'post' || config.method === 'put') && !config.shouldSkipDataParsing) {
        // Create an object to store file data
        const fileData = {};

        // Check if fileData is present
        if (config.fileData) {
          config.fileData.forEach(f => {
            fileData[f] = config.data[f];
            delete config.data[f];
          });
        }
        // Parse object to snakeCase and Form data
        const data = toSnakeCase(config.data);
        config.data = objectToFormData(data);

        // Append files to data to send
        if (config.fileData) {
          Object.entries(fileData).forEach(item => {
            config.data.append(toStringSnakeCase(item[0]), item[1]);
          });
          /*Object.entries(fileData).forEach(async item => {
            console.log("item[0] => ", item[0]);
            if (Array.isArray(item[1])) {
              console.log("is arr");
              item[1].forEach((i, index) => {
                config.data.append(`${item[0]}[]`, i);
                // config.data.append(`${item[0]}[${index}]`, i);
              });
              const bin = await g(item[1]);
            } else config.data.append(item[0], item[1]);
          });*/
        }
      }

      // config.headers['Content-Type'] = 'application/json';
      return config;
    } catch (e) {
      return config;
    }

  },
  error => Promise.reject(error));

customAxios.interceptors.response.use(
  response => {
    if (response && response.data) {
      if (Array.isArray(response.data)) {
        response.data = response.data.map(item => toCamelCase(item));
      } else if (response.data.hasOwnProperty('data')) {
        if (Array.isArray(response.data.data)) {
          // @ts-ignore
          response.data.data = response.data.data.map(item => toCamelCase(item));
        } else response.data.data = toCamelCase(response.data.data);
      } else response.data = toCamelCase(response.data);
    }

    return response;
  });

export default customAxios;
