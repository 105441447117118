/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  'unauthorized.title': 'Unauthorized Access Attempt',
  'unauthorized.detail':
    'You are not authorized to view the page you are trying to access.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.forgot-password-question': 'Forget password?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.buy': 'BUY',
  'user.username': 'Username',

  /* 03.Menu */
  'menu.home': 'Home',
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  "menu.list": "List",
  "menu.create": "Create",
  "menu.products": "Products",
  "menu.promotions": "Marketing",
  "menu.vouchers": "Coupons",
  "menu.sales": "Sales",
  "menu.categories": "Categories",
  "menu.brands": "Brands",
  "menu.taxes": "Taxes",
  "menu.users": "Users",
  "menu.orders": "Orders",
  "menu.settings": "Settings",
  "menu.currencies": "Currencies",
  "menu.page.home.layouts": "Home page layouts",
  "menu.reviews": "Reviews",
  "menu.inbox": "Inbox",

  "categories": "{count, plural, one {Category} other {Categories}}",
  "categories.plural": "Categories",
  "categories.search": "Search categories",
  "categories.found": "{count, plural, =0 {No categories found} one {1 category found} other { {count} categories found}}",

  "products": "{count, plural, one {Product} other {Products}}",
  "products.plural": "Products",
  "products.search": "Search products",
  "products.found": "{count, plural, =0 {No products found} one {1 product found} other { {count} products found}}",
  "products.count": "{count, plural, =0 {No products} one {1 product} other { {count} products}}",
  "brands.found": "{count, plural, =0 {No brands found} one {1 brand found} other { {count} brands found}}",

  // "vouchers": "{count, plural, one {Product} other {Products}}",
  "vouchers.found": "{count, plural, =0 {No coupon found} one {1 coupon found} other { {count} coupons found}}",
  "taxes.found": "{count, plural, =0 {No taxes found} one {1 tax found} other { {count} taxes found}}",
  "sales.found": "{count, plural, =0 {No sales found} one {1 sale found} other { {count} sales found}}",
  "users.found": "{count, plural, =0 {No users found} one {1 user found} other { {count} users found}}",
  "orders.found": "{count, plural, =0 {No orders found} one {1 order found} other { {count} orders found}}",
  "reviews.found": "{count, plural, =0 {No reviews found} one {1 review found} other { {count} reviews found}}",
  "currencies.found": "{count, plural, =0 {No currencies found} one {1 currency found} other { {count} currencies found}}",
  "messages.found": "{count, plural, =0 {No messages found} one {1 message found} other { {count} messages found}}",
  // "orders.products.count": "{count, plural, =0 {No pr found} one {1 order found} other { {count} orders found}}",

  "name.first": "First Name",
  "name.last": "Last Name",
  "name.sur": "Surname",

  /* 04.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',

  /* Request Error */
  "request.error.401": "Connection expired. Please log in again",
  "request.error.403": "You do not have the right to perform this action",
  "request.error.404": "Not found. Please try again later",
  "request.error.500": "An error occurred. Please try again later",
  "request.error.auth.login.failed": "Bad login and/or password",
  "request.error.auth.blockedUser": "Sorry, you are not allowed to log into this association",
  "request.error.auth.notAllowed": "Sorry, you are not allowed to log into this association",
  "request.error.plan.nameAssigned": "The plan already exists",
  "request.error.plan.permissionParsingError": "Error while parsing permission, some fields are missing",
  "request.error.plan.featureParsingError": "Error while parsing feature, some fields are missing",
  "request.error.plan.notFound": "The plan was not found",
  "request.error.plan.maxActivatedPlanReached": "You have already reached the limit of three plans activated",
  "request.error.discount.notFound": "The discount was not found",
  "request.error.user.loginNotUnique": "This login is already assigned",
  "request.error.user.notConnected": "User not connected",
  "request.error.sampleCommunity.subDomainNotUnique": "This sub domain is already taken. Please choose another one",
};
