import Status from '../enums/Status';
import { getStatusColorClassName } from '../helpers/funcHelper';

export default class Order {
  constructor(order) {
    Object.assign(this, order);
    if (order.orderItems) {
      this.orderItems = order.orderItems.map(item => {
        item.productVariant.normalPrice = item.productVariant.price;
        item.productVariant.price = item.price;

        item.productVariant.normalQuantity = item.productVariant.quantity;
        item.productVariant.quantity = item.quantity;
        return item;
      });
    }
  }

  get status () {
      return {
        value: this.state,
        name: this.getStatusName(this.state),
        colorClassName: getStatusColorClassName(this.state)
      }
  }

  getStatusName() {
    switch (this.state) {
      case Status.PENDING:
        return "Awaiting payment";
      case Status.PAID:
        return "Paid";
      case Status.TREATED:
        return "Completed";
      default:
        return "Delivered";
    }
  }
}
