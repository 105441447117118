import {
    SALES,
    SALES_SUCCESS,
    SALES_FAILURE,
} from '../actions';

/**
 * initial state
 */
const INIT_STATE = {
    data: null,
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SALES:
            return { ...state, loading: true };

        case SALES_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        case SALES_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}
