import { NotificationManager } from '../components/common/react-notifications';

const NotificationWrap = {
    error: (message, title = 'Error') => NotificationManager.error(message, title, 3000, null, null, ''),
    warning: (message, title = 'Warning') => NotificationManager.warning(message, title, 3000, null, null, ''),
    success: (message, title = 'Success') => NotificationManager.success(message, title, 3000, null, null, ''),
    info: (message, title = 'Information') => NotificationManager.info(message, title, 3000, null, null, ''),
};

export default NotificationWrap;
