import BaseEnum from './BaseEnum';

export default class Status extends BaseEnum {
    static READ = 'READ';
    static PAID = 'PAID';
    static UNREAD = 'UNREAD';
    static ACTIVE = 'ACTIVE';
    static ABSENT = 'ABSENT';
    static ENABLE = 'ENABLE';
    static PRESENT = 'PRESENT';
    static INSURED = 'INSURED';
    static PENDING = 'PENDING';
    static DELETED = 'DELETED';
    static TREATED = 'TREATED';
    static DISABLE = 'DISABLE';
    static ACCEPTED = 'ACCEPTED';
    static REJECTED = 'REJECTED';
    static IS_MEMBER = 'IS_MEMBER';
    static DELIVERED = 'DELIVERED';
    static ACTIVATED = 'ACTIVATED';
    static NOT_INSURED = 'NOT_INSURED';
    static PENDING_ACCEPT = 'PENDING_ACCEPT';
    static PENDING_REJECT = 'PENDING_REJECT';
    static REQUEST_PENDING = 'REQUEST_PENDING';
    static PENDING_DELETION = 'PENDING_DELETION';
    static PENDING_ACTIVATION = 'PENDING_ACTIVATION';
}
