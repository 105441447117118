import BaseEnum from './BaseEnum';

export default class Type extends BaseEnum {
    static PERCENT = 'PERCENT';
    static CURRENCY = 'CURRENCY';
    static BEGINNING = 'BEGINNING';
    static END = 'END';
    static STATUS = 'STATUS';
    static DATE = 'DATE';
    static MIN_DATE = 'MIN_DATE';
    static MAX_DATE = 'MAX_DATE';
    static VIDEO = 'VIDEO';
    static IMAGE = 'IMAGE';
}
