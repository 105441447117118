import {
    ORDERS,
    ORDERS_SUCCESS,
    ORDERS_FAILURE,
} from '../actions';
import Order from '../../models/Order';

/**
 * initial state
 */
const INIT_STATE = {
    data: null,
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case ORDERS:
            return { ...state, loading: true };

        case ORDERS_SUCCESS:
            return { ...state, loading: false, data: action.payload.map(o => new Order(o)) };

        case ORDERS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}
