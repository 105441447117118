import frMessages from '../locales/fr_FR';

const FrLang = {
  id: 'fr',
  messages: {
    ...frMessages,
  },
  locale: 'fr',
};
export default FrLang;
