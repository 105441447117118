import { addLocaleData, createIntl, IntlProvider } from 'react-intl';
import enLang from './entries/en-US';
import esLang from './entries/es-ES';
import frLang from './entries/fr-FR';
import enRtlLang from './entries/en-US-rtl';
import { getDefaultLanguage } from '../helpers/funcHelper';

// import {createIntl, createIntlCache, RawIntlProvider} from 'react-intl'

// // This is optional but highly recommended
// // since it prevents memory leak
// const cache = createIntlCache()

// const intl = createIntl({
//   locale: 'fr-FR',
//   messages: {}
// }, cache)

const AppLocale = {
  en: enLang,
  fr: frLang,
  es: esLang,
  enrtl: enRtlLang,
};
// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.es.data);
// addLocaleData(AppLocale.enrtl.data);

const currentAppLocale = AppLocale[getDefaultLanguage().id];
console.log("currentAppLocale => ", currentAppLocale);

/*if (currentAppLocale.locale === 'fr') {
  initMoment('fr');
}*/

const intlProvider = new IntlProvider({
  key: currentAppLocale.id,
  locale: currentAppLocale.locale,
  messages: currentAppLocale.messages,
});

// const { intl } = intlProvider.getChildContext();

const intl = createIntl({ locale: currentAppLocale.locale, messages: currentAppLocale.messages })

export const formatMessage = (id, value = {}) => intl.formatMessage({ id, value, });

export default AppLocale;
