import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import * as Constants from './../../constants/defaultValues';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  SET_AUTH_DATA,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

import { adminRoot, currentUser } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';
import api from "./../../helpers/axiosHelper";
import { getFullAuthorisationRequestConfig } from '../../helpers/funcHelper';
import { AUTH, PROFILE } from '../../urls/backendUrl';
import { removeAuthToken, saveAuthToken } from '../../helpers/userHelper';
import NotificationWrap  from '../../helpers/notifHelper';
import { formatMessage } from '../../lang';
import { AUTH as AUTH_FRONT, ROOT } from '../../urls/frontendUrl';

const errorLoginDisplay = (error) => {
  if (error && (error.message.includes('code 400') || error.message.includes('code 401'))) {
    NotificationWrap.error(formatMessage("request.error.auth.login.failed"));
    return;
  }

  NotificationWrap.error(error.message);
};

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchSetAuthUser() {
  yield takeEvery(SET_AUTH_DATA, setAuthUser);
}

const getAuthData = async () => {
  return await api.get(PROFILE.INFORMATION);
};

function* setAuthUser({ callback }) {
  try {
    const authUserResponse = yield call(getAuthData);

    yield put(loginUserSuccess(authUserResponse.data));
  } catch (e) {

  } finally {
    if (callback) callback();
  }
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const config = getFullAuthorisationRequestConfig();

  const _data = {};
  _data.username = email;
  _data.password = password;
  _data.grantType = Constants.backendUrl.oauth.grantType;
  _data.clientId = Constants.backendUrl.oauth.clientId;
  _data.clientSecret = Constants.backendUrl.oauth.clientSecret;

  delete _data.login;

  return await api.post(AUTH.LOGIN, _data, config);
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    let tokens = yield call(loginWithEmailPasswordAsync, email, password);
    if (tokens.data) {
      tokens = tokens.data;
    } else throw new Error("Something went wrong");

    // Persist tokens data
    saveAuthToken(tokens.accessToken, tokens.tokenType, tokens.expiresIn, tokens.refreshToken);

    // Fetch auth data
    let authUserResponse = yield call(getAuthData);

    yield put(loginUserSuccess(authUserResponse.data));

    // Redirect to the wanted url or the main page if not present
    history.push(history.location.state && history.location.state.fromUrl ? history.location.state.fromUrl : ROOT);
  } catch (error) {
    errorLoginDisplay(error);
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser);
}

const registerUserAsync = async (email, firstName, lastName, password) =>
  await api.post(AUTH.REGISTER, {email, firstName, lastName, password});

function* registerUser({ payload }) {
  const { email, firstName, lastName, password } = payload.user;
  const { history } = payload;
  try {
    const response = yield call(
      registerUserAsync,
      email, firstName, lastName, password
    );

    const registeredUser = response.data;

    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    // errorLoginDisplay(error);
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout() {
  removeAuthToken();
  window.location.reload();
}

export function* watchForgotPassword() {

  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {

  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {

  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {

  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchSetAuthUser),
    fork(watchRegisterUser),
    fork(watchResetPassword),
    fork(watchForgotPassword),
  ]);
}
