import * as Constants from './../constants/defaultValues';

export const BASE = `${Constants.backendUrl.baseUrl}`;

const shouldHavePublic = false;

const publicPrefix = shouldHavePublic ? 'public/' : '';

export const AUTH = {
    LOGIN: 'oauth/token',
    LOGIN_WITH_SERVICE_NUMBER: 'auth/login',
    GET_ALL_BY_NAME_AND_URL: 'auth/cgu/by-url',
    COUNTRY: {
        LIST: 'auth/countries',
        OPERATORS: 'auth/countries/{country}/microcap-operators'
    },
    REGISTER: {
        PERSON: publicPrefix + 'users/persons',
        ORGANISATION: publicPrefix + 'users/organisations'
    },
    RESET_PASSWORD: {
        MAIN: 'auth/reset-password',
        LINK: 'auth/send-reset-password-link',
    },
};

export const PROFILE = {
    INFORMATION: 'auth/me',
    INFORMATION_WITH_SERVICE_NUMBER: 'auth/me/mandate',
};

export const CATEGORIES = {
    GET_ALL: '/api/admin/categories',
    CREATE: '/api/admin/categories',
    GET_ONE: '/api/admin/categories/{id}',
    UPDATE: '/api/admin/categories/{id}',
    UPDATE_STATUS: '/api/admin/categories/{id}/status',
    GET_ALL_ROOT: '/api/admin/categories/root',
};

export const BRANDS = {
    CREATE: '/api/admin/brands',
    GET_ALL: '/api/admin/brands',
    GET_ONE: '/api/admin/brands/{id}',
    UPDATE: '/api/admin/brands/{id}',
    DELETE: '/api/admin/brands/{id}',
};

export const PRODUCTS = {
    CREATE: '/api/admin/products',
    GET_ALL: '/api/admin/dashboard/products',
    GET_ONE: '/api/admin/products/{id}',
    UPDATE: '/api/admin/products/{id}',
    DELETE: '/api/admin/products/{id}',
    COPY: '/api/admin/products/copy/{id}',
    STATUS: '/api/admin/products/{id}/status',
    VARIANTS: {
        ADD: '/api/admin/products/{id}/variants',
        UPDATE: '/api/admin/products/variants/{id}',
        DELETE: '/api/admin/products/variants/{id}',
        STATUS: '/api/admin/products/variants/{id}/status',
    },
    MEDIA: {
        ADD: {
            IMAGE: '/api/admin/products/variants/{id}/image',
            VIDEO: '/api/admin/products/variants/{id}/video',
        },
        UPDATE: '/api/admin/products/variants/media/{id}',
        DELETE: '/api/admin/products/variants/media/{id}',
        SET_DEFAULT: '/api/admin/products/variants/media/{id}/default',
        SET_POSITION: '/api/admin/products/variants/media/{id}/position'
    }
};

export const VOUCHERS = {
    GET_ALL: '/api/admin/vouchers',
    CREATE: '/api/admin/vouchers',
    GET_ONE: '/api/admin/vouchers/{id}',
    UPDATE: '/api/admin/vouchers/{id}',
    UPDATE_STATUS: '/api/admin/vouchers/{id}/status',
};

export const SALES = {
    GET_ALL: '/api/admin/sales',
    CREATE: '/api/admin/sales',
    GET_ONE: '/api/admin/sales/{id}',
    UPDATE: '/api/admin/sales/{id}',
    UPDATE_STATUS: '/api/admin/sales/{id}/status',
};

export const PROVINCES = {
    CREATE: '/api/admin/provinces/tax',
    UPDATE: '/api/admin/provinces/{id}',
    GET_ALL: '/api/admin/provinces',
    COUNTRIES: {
        GET_ALL: '/api/admin/provinces/countries',
        GET_ALL_OF_COUNTRY: '/api/admin/provinces/countries/{code}',
    },
};

export const DASHBOARD = {
    USERS: {
        GET_ALL: '/api/admin/dashboard/users',
        GET_ONE: '/api/admin/dashboard/users/{id}',
        ORDERS: {
            GET_ALL: '/api/admin/dashboard/users/{id}/orders',
        }
    },
    ORDERS: {
        GET_ALL: '/api/admin/dashboard/orders',
        GET_ONE: '/api/admin/dashboard/orders/{id}',
        PRINT: '/api/admin/dashboard/orders/{id}/print',
        TREAT: '/api/admin/dashboard/orders/{id}/tracking/add',
        DELIVER: '/api/admin/dashboard/orders/{id}/deliver',
    },
    MESSAGES: {
        GET_ALL: '/api/admin/dashboard/messages',
        DELETE: '/api/admin/dashboard/messages',
        MARK_AS_READ: '/api/admin/dashboard/messages/read',
    }
};

export const REVIEWS = {
    CREATE: '/api/admin/dashboard/reviews',
    GET_ALL: '/api/admin/dashboard/reviews',
    CHANGE_VISIBILITY: '/api/admin/dashboard/reviews/{id}',
};

export const CURRENCIES = {
    GET_ALL: '/api/admin/currency',
    CREATE: '/api/admin/currency',
    GET_ONE: '/api/admin/currency/{id}',
    UPDATE: '/api/admin/currency/{id}',
    DELETE: '/api/admin/currency/{id}',
};

export const joinBaseUrl = to => BASE + to;

export const joinBaseUrlWithParams = (to, params) => {
    let url = BASE + to;

    params.forEach(param => {
        url = url.replace(`{${param.param}}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

export const joinBaseUrlWithRequestParams = (to, params) => {
    let url = BASE + to;
    let i = 0;
    params.forEach(param => {
        if(i === 0)
            url = url + '?' + param.param + '=' + param.value
        else
        url = url + '&' + param.param + '=' + param.value
    });

    return url;
};

export const joinBaseUrlWithParamsId = (to, id) => {
    return joinBaseUrlWithParams(to, [{param: 'id', value: id}]);
};
