import {
    PROVINCES,
    PROVINCES_SUCCESS,
    PROVINCES_FAILURE,
} from '../actions';

/**
 * initial state
 */
const INIT_STATE = {
    data: null,
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PROVINCES:
            return { ...state, loading: true };

        case PROVINCES_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        case PROVINCES_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}
