import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import requestGlobal from './requestGlobal/reducer';
import categories from './categories/reducer';
import products from './products/reducer';
import brands from './brands/reducer';
import vouchers from './vouchers/reducer';
import sales from './sales/reducer';
import provinces from './provinces/reducer';
import users from './users/reducer';
import orders from './orders/reducer';
import reviews from './reviews/reducer';
import currencies from './currencies/reducer';
import messages from './messages/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  requestGlobalLoader: requestGlobal,
  categories,
  products,
  brands,
  vouchers,
  provinces,
  sales,
  users,
  orders,
  reviews,
  currencies,
  messages,
});

export default reducers;
