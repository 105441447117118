import {
    PRODUCTS,
    PRODUCTS_SUCCESS,
    PRODUCTS_FAILURE,
} from '../actions';
import Product from '../../models/Product';

/**
 * initial state
 */
const INIT_STATE = {
    data: null,
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PRODUCTS:
            return { ...state, loading: true };

        case PRODUCTS_SUCCESS:
            return { ...state, loading: false, data: action.payload.map(product => new Product(product)) };

        case PRODUCTS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}
